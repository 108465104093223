//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-176:_5280,_2596,_8588,_40,_6292,_6300,_720,_2728;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-176')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-176', "_5280,_2596,_8588,_40,_6292,_6300,_720,_2728");
        }
      }catch (ex) {
        console.error(ex);
      }